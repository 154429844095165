<template>
  <div class="e-overall e-overall--login">
    <div
      v-if="!$route.meta.requiresAuth"
      v-show="!offcanvasOpen"
      class="e-canvas__background"
    />
    <transition name="slide-to-left">
      <div class="e-canvas e-canvas--register" v-show="!offcanvasOpen">
        <TheHeader />

        <div
          class="e-module e-module--limited e-module--shadow"
          style="z-index: 1"
        >
          <RegisterForm />
        </div>

        <TheFooter :hide-logos="true" />
      </div>
    </transition>

    <transition name="slide-from-right">
      <TheOffcanvas v-show="offcanvasOpen" />
    </transition>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import TheFooter from 'molecules/TheFooter/TheFooter'
import TheHeader from 'molecules/TheHeader/TheHeader'
import TheOffcanvas from 'molecules/TheOffcanvas/TheOffcanvas'
import RegisterForm from 'organisms/Forms/Register/Registerform'

export default {
  components: {
    TheFooter,
    TheHeader,
    TheOffcanvas,
    RegisterForm
  },
  computed: {
    ...mapGetters(['offcanvasOpen', 'userInfo'])
  }
}
</script>

<style lang="scss" scoped>
.e-canvas--register {
  > .e-module {
    margin-top: rem(15px);
    margin-bottom: rem(15px);
  }
}
</style>
